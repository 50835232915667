import React, { Component } from 'react';
import Loading from '../common/loading';

class Home extends Component {
  constructor() {
    super();
    this.checkOperatingSystem = this.checkOperatingSystem.bind(this);
    this.openIOS = this.openIOS.bind(this);
    this.openAndroid = this.openAndroid.bind(this);
    this.openWebsite = this.openWebsite.bind(this);
  }

  componentDidMount() {
    document.title = 'Ride a Flamingo Scooter';
    this.checkOperatingSystem();
  }

  checkOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Android
    if (/android/i.test(userAgent)) {
      return this.openAndroid();
    }

    // iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return this.openIOS();
    }

    // Other
    return this.openWebsite();
  }

  openIOS() {
    window.location = 'itms-apps://itunes.apple.com/nz/app/flamingo-scooters/id1446388027';
    setTimeout(() => {
      window.location = 'https://itunes.apple.com/nz/app/flamingo-scooters/id1446388027';
    }, 1500);
  }

  openAndroid() {
    window.location = 'https://play.google.com/store/apps/details?id=com.flamingoscooters.android';
  }

  openWebsite() {
    window.location = 'https://rideflamingo.com';
  }

  render() {
    return (
      <div className="fm-home">
        <Loading />
      </div>
    );
  }
}

export default Home;
