import React from 'react';
import { Link } from 'react-router-dom';

class TopBar extends React.Component {
  render() {
    return (
      <div className='fm-top-bar'>
        <div className="fm-top-bar-content">
          <Link className="fm-brand fm-top-bar-brand" to="/" onClick={this.handleClick}>Flamingo</Link>
        </div>
      </div>
    );
  }
}

export default TopBar;
